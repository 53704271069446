




























































































































.passport-pictures {
  margin-bottom: 50px;
}
